/*global $*/
/*global miniShop2*/
/*eslint no-undef: "error"*/
export default class InputAddressSelect {
    classBlock = 'input-address-select--block'
    classButton = 'input-address-select--button'
    classItem = 'input-address-select--item'
    className = 'input-address-select--name'

    classBlockOpen = 'input-address-select---open'

    constructor () {
        let self = this
        let activeID = $(`.${self.classBlock}`).data('active')
        const delay = (time) => {
            return new Promise((resolve) => setTimeout(resolve, time))
        }

        $(`.${self.classButton}`).click(function () {
            $(this).closest(`.${self.classBlock}`).toggleClass(self.classBlockOpen)
        })

        $(`.${self.classItem}`).click(function () {
            let objBlock = $(this).closest(`.${self.classBlock}`)
            let name = $(this).data('name')

            objBlock.removeClass(self.classBlockOpen)
            objBlock.find(`.${self.className}`).text(name)

            // Добавляем поля к заказу и считаем общею доставку
            delay(100)
                .then(() => {
                    // street
                    miniShop2.Order.add("street", $(this).data('street'))
                    console.log('street')

                    return delay(850)
                })
                .then(() => {
                    // building
                    miniShop2.Order.add("building", $(this).data('building'))
                    console.log('building')

                    return delay(850)
                })
                .then(() => {
                    // entrance
                    miniShop2.Order.add("entrance", $(this).data('entrance'))
                    console.log('entrance')

                    return delay(300)
                })
                .then(() => {
                    // floor
                    miniShop2.Order.add("floor", $(this).data('floor'))
                    console.log('floor')

                    return delay(300)
                })
                .then(() => {
                    // room
                    miniShop2.Order.add("room", $(this).data('room'))
                    console.log('room')

                    return delay(300)
                })
        })

        if ($(`.${self.classBlock}`).length) {
            if (activeID !== '') {
                $(`.${self.classItem}`).eq(parseInt(activeID)).click()
                console.log('click default: ' + parseInt(activeID))
            }
        }
    }
}
