/*global $*/
/*eslint no-undef: "error"*/
export default class DateTab {
    classItem = 'date-tab--item'
    classButton = 'date-tab--button'
    classBlock = 'date-tab--block'
    classBlockActive = 'date-tab__block---active'
    classInput = 'date-tab--input'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            let block = $(this).data('block')

            $(this).closest(`.${self.classItem}`).removeClass('date-tab---active-1')
            $(this).closest(`.${self.classItem}`).removeClass('date-tab---active-2')
            $(this).closest(`.${self.classItem}`).addClass(`date-tab---active-${block}`)

            $(this).closest(`.${self.classItem}`).find(`.${self.classBlock}`).removeClass(self.classBlockActive)

            if ($(this).closest(`.${self.classItem}`).find(`.${self.classBlock}[data-block='${block}']`).length) {
                $(this).closest(`.${self.classItem}`).find(`.${self.classBlock}[data-block='${block}']`).addClass(self.classBlockActive)
            }

            if (parseInt(block) === 1) {
                $(this).closest(`.${self.classItem}`).find(`.${self.classInput}`).val('')
            }
        })
    }
}
